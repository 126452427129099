.checkbox {
    position: relative;
    top: -0.04em;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    outline: none;
    color: var(--text-color);
    list-style: none;
    font-size: var(--font-size-base);
    font-variant: var(--font-variant-base);
    line-height: var(--line-height-base);
    font-feature-settings: var(--font-feature-settings-base);
    white-space: nowrap;
    cursor: pointer;
}

.checkbox-wrapper {
    display: inline-flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    font-size: 1.4rem;
    line-height: 1.57142857;
    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    cursor: pointer;
}

.checkbox-wrapper.checkbox-big {
    align-items: center;
}

.checkbox-wrapper-disabled {
    opacity: 0.5;
}

.checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    background-color: var(--checkbox-check-color);
    border: var(--checkbox-border-width) var(--border-style-base) var(--border-color-base);
    border-radius: var(--border-radius-sm);
    border-collapse: separate;
    transition: all 0.3s;
}

.checkbox-inner:after {
    --check-width: calc(var(--checkbox-size) / 14 * 3.5px);
    --check-height: calc(var(--checkbox-size) / 14 * 7px);
    position: absolute;
    top: 50%;
    left: 21%;
    display: table;
    margin-top: -1px;
    margin-bottom: 1px;
    width: var(--check-width);
    height: var(--check-height);
    border: 2px solid var(--checkbox-check-color);
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    content: ' ';
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
}

.checkbox-wrapper.checkbox-big .checkbox-inner {
    width: var(--checkbox-size-big);
    height: var(--checkbox-size-big);
}

.checkbox-checked .checkbox-inner {
    background-color: var(--checkbox-check-color);
}

.checkbox-checked .checkbox-inner:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: table;
    width: var(--checkbox-inner-size-base);
    height: var(--checkbox-inner-size-base);
    margin: 0;
    border: 0;
    background: url('../../assets/icons/check.svg') 50% no-repeat;
    background-size: contain;
    transform: translate(-50%, -50%);
    opacity: 1;
    content: ' ';
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.checkbox-wrapper.checkbox-big .checkbox-checked .checkbox-inner:after {
    width: var(--checkbox-inner-size-big-base);
    height: var(--checkbox-inner-size-big-base);
}

.checkbox-wrapper-disabled .checkbox-inner,
.checkbox-disabled .checkbox-inner {
    background-color: var(--checkbox-check-color);
}

.checkbox-error .checkbox-inner {
    border-color: var(--negative-default);
}

.checkbox-label {
    color: var(--primary-default);
    font-size: 1.4rem;
    line-height: 1.14285714;
}

.checkbox-description {
    color: var(--primary-light);
    margin-bottom: 0;
}

.checkbox-group {
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--text-color);
    list-style: none;
    font-size: var(--font-size-base);
    font-variant: var(--font-variant-base);
    line-height: var(--line-height-base);
    font-feature-settings: var(--font-feature-settings-base);
}

.checkbox-group-horizontal {
    flex-direction: row;
}

.checkbox-group .checkbox-wrapper + .checkbox-wrapper {
    margin-left: 0;
    margin-top: 20px;
}

.checkbox-group-horizontal .checkbox-wrapper + .checkbox-wrapper {
    margin-left: 40px;
    margin-top: 0;
}

.checkbox-wrapper + span,
.checkbox + span {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    padding: 0;
}

.checkbox-wrapper + span > span,
.checkbox + span > span,
.checkbox-wrapper + span > p,
.checkbox + span > p {
    padding-left: 10px;
}

.checkbox-wrapper + span > span:empty,
.checkbox + span > span:empty,
.checkbox-wrapper + span > p:empty,
.checkbox + span > p:empty {
    padding-left: 0;
}

.checkbox-wrapper:hover .checkbox-inner,
.checkbox:hover .checkbox-inner {
    border-color: var(--primary-light);
    background-color: var(--secondary-lighter);
}

.checkbox-wrapper-disabled:hover .checkbox-inner,
.checkbox-disabled:hover .checkbox-inner {
    background-color: var(--checkbox-check-color);
}

.checkbox-wrapper:hover .checkbox-inner,
.checkbox:hover .checkbox-inner,
.checkbox-input:focus + .checkbox-inner {
    border-color: var(--checkbox-color);
}

.checkbox-input:focus + .checkbox-inner,
.checkbox-checked .checkbox-inner {
    border-color: var(--primary-lighter);
}

.checkbox-input:focus + .checkbox-inner {
    outline-width: initial;
    outline-style: auto;
    outline-color: Highlight;
    outline: 5px auto -webkit-focus-ring-color;
}

.checkbox-error:hover .checkbox-inner {
    border-color: var(--negative-dark);
}

.checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.checkbox-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    border: 1px solid var(--checkbox-color);
    border-radius: var(--border-radius-sm);
    content: '';
    animation: antCheckboxEffect 0.36s ease-in-out;
    animation-fill-mode: both;
}

.checkbox:hover:after,
.checkbox-wrapper:hover .checkbox:after {
    visibility: visible;
}

.checkbox-disabled {
    cursor: not-allowed;
}

.checkbox-disabled.checkbox-checked .checkbox-inner:after {
    border-color: var(--disabled-color);
    animation-name: none;
}

.checkbox-disabled .checkbox-input {
    cursor: not-allowed;
}

.checkbox-disabled .checkbox-inner {
    background-color: var(--input-disabled-bg-color);
    border-color: var(--border-color-base) !important;
}

.checkbox-disabled .checkbox-inner:after {
    border-color: var(--input-disabled-bg-color);
    border-collapse: separate;
    animation-name: none;
}

.checkbox-disabled + span {
    color: var(--disabled-color);
    cursor: not-allowed;
    padding-right: 8px;
    padding-left: 8px;
}

.checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
}

.checkbox-group-item {
    display: inline-block;
    margin-right: 8px;
}

.checkbox-group-item:last-child {
    margin-right: 0;
}

.checkbox-group-item + .checkbox-group-item {
    margin-left: 0;
}

.checkbox-indeterminate .checkbox-inner {
    background-color: var(--white);
    border-color: var(--border-color-base);
}

.checkbox-indeterminate .checkbox-inner:after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: var(--checkbox-color);
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: ' ';
}

.checkbox-indeterminate.checkbox-disabled .checkbox-inner:after {
    background-color: var(--disabled-color);
    border-color: var(--disabled-color);
}
