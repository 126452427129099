.TextInput {
    padding: var(--input-vertical-padding) var(--input-horizontal-padding);
    background-color: transparent;
    font-size: 1.4rem;
    line-height: calc(16 / 14);
}

.TextInput-affix-wrapper .rc-tooltiped-text {
    margin-right: 0;
}

.TextInput-affix-wrapper.TextInput-suffix {
    right: 10px;
    color: var(--input-color);
}

.form-group_invalid .TextInput-affix-wrapper.TextInput-suffix {
    color: var(--input-error-color);
}

.form-group_disabled .TextInput-affix-wrapper.TextInput-suffix {
    color: var(--primary-light);
}

.form-group_disabled .TextInput-affix-wrapper {
    opacity: 0.5;
    pointer-events: none;
}

.TextInput-affix-wrapper:hover .TextInput:not(.TextInput-disabled) {
    border-color: var(--primary-light);
}

.TextInput:hover {
    border-color: var(--primary-light);
}

.TextInput:focus,
.TextInput_focused,
.TextInput_focused .TextInput-input,
.TextInput_focused .TextInput-group:hover {
    box-shadow: none;
}

.TextInput_invalid input {
    border-color: var(--input-error-color);
}

.TextInput_invalid .rc-tooltiped-text {
    color: var(--input-error-color);
}

.TextInput_invalid:hover,
.TextInput_invalid.TextInput-affix-wrapper:hover .TextInput:not(.TextInput-disabled) {
    border-color: var(--input-error-hover-color);
}

.TextInput[disabled],
.form-group_disabled .TextInput,
.TextInput_disabled {
    color: var(--input-disabled-color);
    box-shadow: none;
    background: transparent;
}

.TextInput-group {
    border: 1px solid var(--input-group-border-color);
    border-radius: var(--border-radius-base);
}

.form-group_disabled .TextInput-group-wrapper .TextInput-group {
    cursor: not-allowed;
}

.TextInput_invalid .TextInput-group {
    border-color: var(--input-error-color);
}

.TextInput-group:hover {
    border-color: var(--input-group-border-color);
}

.form-group_disabled .TextInput-group:hover {
    border-color: var(--input-group-border-color);
}

.TextInput_invalid .TextInput-group:hover {
    border-color: var(--input-error-hover-color);
}

.TextInput-group input {
    border: 0;
}

.TextInput-group input:hover {
    border: 0;
}

.TextInput-group-addon {
    position: relative;
    color: var(--input-color);
    border: 0;
    background-color: transparent;
}

.TextInput-group-addon button {
    padding: 0;
    height: var(--line-height-base);
    min-width: auto;
    color: var(--input-max-button-color);
    text-decoration: underline;
    border: none;
}

.TextInput-group-addon button:hover {
    color: var(--input-max-button-hover-color);
}

.addon-after-border:before {
    position: absolute;
    top: 6px;
    left: 0;
    z-index: 1;
    display: inline-block;
    width: 1px;
    height: calc(var(--input-height-base) - 12px);
    content: '';
    background-color: var(--input-border-color);
}

.form-group_disabled .TextInput-group-addon {
    opacity: 0.5;
}

.TextInput:focus {
    @apply --focus;
}

.with-select-box .select__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    padding: 0;
    text-align: center;
    text-decoration: none;
}

.with-select-box .TextInput-group-addon {
    padding: 0;
}

.with-select-box .select__dropdown {
    margin-top: 0;
    right: -1px;
    width: calc(100% + 1px);
}

.with-select-box .TextInput-group-addon:before {
    top: 0;
    height: 100%;
}

.with-select-box .select {
    min-width: 70px;
}

.with-select-box .select__option .checkbox-wrapper {
    padding: 12px 10px;
}
