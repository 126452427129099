.bread-crumbs {
    font-size: var(--font-size-base);
    line-height: calc(16 / 14);
    margin-bottom: var(--breadcrumbs-margin);
}

.bread-crumbs a {
    color: var(--breadcrumb-color)!important;
}

.bread-crumbs a:hover {
    color: var(--breadcrumb-color-hover)!important;
}

.bread-crumbs a:not(:first-child) {
    margin-left: var(--breadcrumbs-margin);
}

.bread-crumbs a:before {
    display: inline-block;
    width: calc(var(--breadcrumb-item-size) * 0.9);
    height: calc(var(--breadcrumb-item-size) * 0.1);
    vertical-align: middle;
    margin-right: var(--breadcrumbs-margin);
    background-color: var(--breadcrumb-color);
    content: '';
}

.bread-crumbs a:first-child:before {
    width: var(--breadcrumb-item-size);
    height: calc(var(--breadcrumb-item-size) * 0.9);
    background-color: transparent;
    vertical-align: baseline;
    background: url('../../assets/icons/breadcrumbs-arrow.svg') no-repeat center;
}

.bread-crumbs__current {
    margin-left: var(--breadcrumbs-margin);
    color: var(--breadcrumb-color-active)!important;
}

.bread-crumbs__current:before {
    display: inline-block;
    width: calc(var(--breadcrumb-item-size) * 0.9);
    height: calc(var(--breadcrumb-item-size) * 0.1);
    vertical-align: middle;
    color: var(--breadcrumb-color-active)!important;
    background-color: var(--breadcrumb-color-active);
    margin-right: var(--breadcrumbs-margin);
    content: '';
}
