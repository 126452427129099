.AccordionPanel-content {
    position: relative;
    z-index: 1;
    overflow: hidden;
    color: var(--primary-default);
    background-color: transparent;
    border-top: 0;
    transition: var(--transition);
    max-height: 0;
    transition-property: max-height;
}

.AccordionPanel-content-box {
    padding: 0 var(--padding-base) var(--padding-base);
    transition: none;
}

.AccordionPanel-content__open {
    transition-duration: 0s;
}

.AccordionPanel-content__active {
    height: auto;
    max-height: 1000rem;
    transition-property: max-height;
}
