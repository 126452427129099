.form-group {
    margin-bottom: var(--form-group-margin-bottom);
}

.form-group > p {
    margin-bottom: 0;
    color: var(--primary-light);
}

.form-group_disabled {
    cursor: not-allowed;
}

.form-group_disabled > p {
    opacity: 0.5;
}
