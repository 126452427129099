.Textarea {
    padding:  var(--textarea-vertical-padding) var(--textarea-horizontal-padding);
    width: 100%;
    border: 1px solid var(--primary-lighter);
    border-radius: var(--border-radius-base);
    min-height: var(--textarea-minheight);
    background-color: transparent;
    resize: none;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.57142857;
}

.Textarea:hover {
    border-color: var(--primary-light);
}

.form-group_disabled .Textarea:hover {
    cursor: not-allowed;
    border-color: var(--primary-lighter);
}

.form-group_invalid .Textarea:hover {
    border-color: var(--negative-dark);
}

.form-group_disabled .Textarea {
    opacity: 0.5;
    pointer-events: none;
}

.form-group_invalid .Textarea {
    border-color: var(--negative-default);
}
