.Toggle {
    display: inline-block;
}
input.switch {
    display: none;
}

input.switch + label {
    position: relative;
    display: block;
    cursor: pointer;
    user-select: none;
    border-radius: var(--toggle-border-radius);
}

input.switch + label span {
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

input.switch-round + label {
    display: flex;
    align-items: center;
    width: var(--toggle-width);
    height: var(--toggle-height);
    background-color: var(--primary-light);
    border-radius: var(--toggle-border-radius);
}

input.switch-round + label:before,
input.switch-round + label:after {
    position: absolute;
    display: block;
    content: '';
    transition: var(--switch-duration);
}

input.switch-round + label:before {
    width: 100%;
    height: 100%;
    border-radius: var(--toggle-border-radius);
}

input.switch-round + label:after {
    position: absolute;
    left: var(--toggle-padding);
    width: var(--switch-circle-width);
    height: var(--switch-circle-width);
    background-color: var( --background-content);
    border-radius: 100%;
}

input.switch-round:checked + label:before {
    border: 1px solid var(--accent-dark);
    background-color: var(--switch-color);
}

input:disabled.switch-round + label {
    opacity: 0.5;
    cursor: not-allowed;
}

.toggle-error input.switch-round + label:before {
    border: 1px solid var(--negative-light);
    background-color: var(--negative-default);
}

input.switch-round:checked + label:after {
    left: calc(100% - var(--toggle-padding) - var(--switch-circle-width));
}