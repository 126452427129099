.AccordionPanel-extra {
    float: none;
    display: flex;
    margin-left: var(--margin-base);
}

.AccordionPanel-extra-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3px;
    height: var(--collapse-extra-counter-height);
    color: var(--collapse-extra-counter-color);
    background-color: var(--collapse-extra-counter-bg-color);
    border-radius: 3px;
    font-size: var(--font-size-base);
    line-height: 0;
    font-weight: bold;
}
