.Tooltip.rc-tooltip {
    max-width: var(--tooltip-max-width);
}

.Tooltip .rc-tooltip-inner {
    padding: var(--margin-base);
    box-shadow: 0 2px 8px var(--box-shadow);
    border: 1px solid var(--primary-lighter);
    color: var(--primary-default);
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    border-radius: var(--border-radius-base);
    background-color: var(--tooltip-bg);
}

.Tooltip .rc-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.Tooltip .ant-tooltiped-text:hover {
    cursor: pointer;
}

.Tooltip .rc-tooltip-table .rc-tooltip-inner {
    padding: calc(var(--margin-base) - 9px) var(--margin-base);
    max-width: calc(var(--tooltip-max-width) - 50px);
}

.Tooltip .rc-tooltip-arrow:after {
    position: absolute;
    z-index: 1061;
    display: block;
    overflow: visible;
    width: 0;
    height: 0;
    background: none;
    content: '';
    border-style: solid;
    border-color: transparent;
}

.Tooltip .rc-tooltip-arrow:before {
    display: none;
}

.Tooltip .rc-tooltip-placement-top .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-topLeft .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-topRight .rc-tooltip-arrow:after {
    bottom: calc(var(--tooltip-distance) - var(--tooltip-arrow-width));
    border-right-width: var(--tooltip-arrow-width);
    border-left-width: var(--tooltip-arrow-width);
    border-top-width: calc(var(--tooltip-arrow-width) + 1px);
    border-bottom-width: 0;
    border-top-color: var(--bright);
    top: calc(calc(var(--tooltip-arrow-width) + 2px) * -1);
    left: calc(var(--tooltip-arrow-width) * -1);
}

.Tooltip .rc-tooltip-placement-right .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-rightTop .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-rightBottom .rc-tooltip-arrow:after {
    border-width: var(--tooltip-arrow-width) var(--tooltip-arrow-width) var(--tooltip-arrow-width) 0;
    border-right-color: var(--bright);
    left: 1px;
    top: calc(var(--tooltip-arrow-width) * -1);
}

.Tooltip .rc-tooltip-placement-left .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-leftTop .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-leftBottom .rc-tooltip-arrow:after {
    border-width: var(--tooltip-arrow-width) 0 var(--tooltip-arrow-width) var(--tooltip-arrow-width);
    border-left-color: var(--bright);
    top: calc(var(--tooltip-arrow-width) * -1);
    right: 1px;
}

.Tooltip .rc-tooltip-placement-bottom .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-bottomRight .rc-tooltip-arrow:after {
    border-width: 0 var(--tooltip-arrow-width) var(--tooltip-arrow-width);
    border-bottom-color: var(--bright);
    top: 1px;
    left: calc(var(--tooltip-arrow-width) * -1);
}

/* dark theme */
.rc-tooltip-dark .ant-tooltip-arrow:after {
    display: none;
}

.rc-tooltip-content .Tooltip.rc-tooltip-dark {
    border: 0;
}

.Tooltip.rc-tooltip-dark .rc-tooltip-inner {
    color: var(--bright);
    border: 0;
    background-color: var(--secondary-default);
}

.rc-tooltip-dark > .rc-tooltip-content > .rc-tooltip-arrow {
    box-shadow: none !important;
}

.rc-tooltip-dark.rc-tooltip-placement-top > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-topLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-topRight > .rc-tooltip-content > .rc-tooltip-arrow {
    border-right-color: var(--secondary-default);
    border-bottom-color: var(--secondary-default);
}

.rc-tooltip-dark.rc-tooltip-placement-right > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-rightTop > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-rightBottom > .rc-tooltip-content > .rc-tooltip-arrow {
    border-bottom-color: var(--secondary-default);
    border-left-color: var(--secondary-default);

}

.rc-tooltip-dark.rc-tooltip-placement-left > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-leftTop > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-leftBottom > .rc-tooltip-content > .rc-tooltip-arrow {
    border-top-color: var(--secondary-default);
    border-right-color: var(--secondary-default);
}

.rc-tooltip-dark.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-bottomLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-bottomRight > .rc-tooltip-content > .rc-tooltip-arrow {
    border-top-color: var(--secondary-default);
    border-left-color: var(--secondary-default);
}