.profile-menu {
    display: flex;
    justify-content: stretch;
    align-items: center;
}

.profile-menu .avatar {
    width: var(--avatar-size);
    height: var(--avatar-size);
    cursor: pointer;
}

.profile-menu .avatar img {
    margin: 0;
}

.profile-menu__notification {
    overflow-y: auto;
    max-height: var(--notification-max-height, 400px);
    min-width: var(--profile-menu-conten-min-width);
    max-width: var(--notification-max-width);
    padding: 0 18px;
    line-height: 1.5;
}

.profile-menu__notification > .NotificationsError {
    padding: 18px 0;
}

.profile-menu__content {
    min-width: var(--profile-menu-conten-min-width);
    padding: 18px;
    text-align: center;
}

.profile-menu__content .avatar {
    width: var(--avatar-size-lg);
    height: var(--avatar-size-lg);
}

.profile-menu__content .logout-button {
    display: block;
    height: auto;
    margin: 10px auto 0;
    padding: 0;
    border: none;
    color: var(--secondary-default);
    font-size: 1.4rem;
    line-height: 1.57142857;
    text-decoration: underline;
}

.profile-menu__content .logout-button:hover {
    color: var(--secondary-dark);
}

.profile-menu__content .logout-button:active {
    color: var(--secondary-darker);
}

.profile-menu__content .logout-button span {
    text-decoration: underline;
}

.profile-menu__name {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 1.8rem;
    color: var(--primary-default);
    line-height: 1.33333333;
}

.profile-menu__email,
.profile-menu__additional {
    margin-bottom: 18px;
    color: var(--secondary-default);
    font-size: 1.2rem;
    line-height: 1.16666667;
}

.profile-menu .alert-icon-wrapper {
    display: flex;
    height: 20px;
    margin-right: var(--padding-base);
}

.profile-menu .alert-icon-wrapper > span {
    width: 40px;
    text-align: center;
}

.profile-menu .alert {
    position: relative;
    color: var(--secondary-default);
    font-size: 2rem;
    line-height: 1;
}

.profile-menu .alert:after {
    position: absolute;
    top: -3px;
    right: -3px;
    display: inline-block;
    width: var(--navigation-link-marker-size);
    height: var(--navigation-link-marker-size);
    vertical-align: super;
    box-sizing: content-box;
    border-radius: 50%;
    border: 2px solid var(--background-header);
    background-color: var(--negative-light);
    margin-left: calc(var(--navigation-link-marker-size) / 3);
    content: ' ';
}

.profile-menu .alert.alert-empty:after {
    display: none;
}

@media only screen and (max-width: 480px) {
    .profile-menu__notification {
        max-width: 100%;
    }

    .profile-menu .alert-icon-wrapper {
        margin-right: var(--margin-base);
    }

    .profile-menu .avatar {
        width: var(--avatar-size-sm);
        height: var(--avatar-size-sm);
    }
}