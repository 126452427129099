:root {
    --box-shadow: rgba(0, 0, 0, 0.15);
    --white: #fff;
    --white-darker: #f5f5f5;
    --indent-base: 15px;
    --border-radius-base: 2px;
    --margin-base: 20px;
    --padding-base: 30px;
    --transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    /* Icons */
    --arrow-down: url('../assets/icons/arrow-down.svg');
    /* Fonts */
    --body-font-family: Roboto, Helvetica, Arial, sans-serif;
    --font-size-base: 14px;
    --line-height-base-px: 22;
    /* Titles */
    --title-1-margin-bottom: 34px;
    --title-2-margin-bottom: 28px;
    --title-3-margin-bottom: 24px;
    --title-4-margin-bottom: 22px;
    /* Tooltip */
    --tooltip-max-width: 300px;
    --tooltip-color: var(--primary-default);
    --tooltip-bg: var(--bright);
    --tooltip-arrow-width: 7px;
    --tooltip-distance: calc(var(--tooltip-arrow-width) - 1px + 4px);
    --tooltip-arrow-color: var(--secondary-default);
    --tooltip-border-color-base: rgba(0, 0, 0, 0.75);
    /* Popover */
    --popover-arrow-border-width: 5px;
    --popover-inner-content-border-radius: 5px;
    --popover-arrow-shadow-width: 3px;
    --popover-arrow-shadow-width-sqrt: 3px;
    --popover-arrow-offset-vertical: 12px;
    --popover-arrow-offset-horizontal: 16px;
    /* Dropdown */
    --dropdown-menu-font-size: 16px;
    --dropdown-border-radius: 5px;
    /* Accordion */
    --header-icon-size: 20px;
    --collapse-content-padding-left: 30px;
    --collapse-vertical-padding: 12px;
    --collapse-horizontal-padding: 16px;
    --collapse-extra-margin-left: 20px;
    --collapse-extra-counter-height: 18px;
    --collapse-header-line-height: 22px;

    /* Avatar */
    --avatar-default-size: 32px;
    --avatar-large-size: 40px;
    --avatar-small-size: 24px;
    --avatar-font-size: 18px;

    /* ProfileMenu */
    --avatar-size-sm: 28px;
    --avatar-size: 36px;
    --avatar-size-lg: 72px;
    --profile-menu-conten-min-width: 240px;
    --notification-max-width: 290px;
    --notification-max-height: 300px;

    /* Button */
    --btn-vertical-padding: 9px;
    --btn-vertical-padding-sm: 3px;
    --btn-horizontal-padding: var(--padding-base);
    --btn-horizontal-padding-sm: 20px;
    --btn-width-base: 150px;
    --btn-height-base: 45px;
    --btn-height-sm: 30px;
    /* Calendar */
    --event-primary: #4C5484;
    --event-primary-bg: #D2D8FF;
    --event-purple: #7B4C84;
    --event-purple-bg: #F6C8FF;
    --event-orange: #CE805E;
    --event-orange-bg: #FFE6DB;
    --event-green: #72A356;
    --event-green-bg: #D5FFBE;
    --dot-size: 5px;
    /* Datepicker */
    --datepicker-border-color: var(--primary-lighter);
    --datepicker-border-radius: 5px;
    --datepicker-date-color: var(--primary-light);
    --datepicker-header-color: var(--primary-default);
    --datepicker-last-next-month-color: var(--secondary-lighter);
    --datepicker-selected-date-bg-color: var(--accent-default);
    --datepicker-weekend-color: var(--primary-lighter);
    --datepicker-weekend-bg-color: var(--secondary-lighter);
    --datepicker-divider-bg: var(--secondary-lighter);
    /* Infobox */
    --infobox-top-padding: 16px;
    --infobox-left-padding: 18px;
    --infobox-bottom-padding: 18px;
    --infobox-right-padding: 20px;
    /* Inputs */
    --form-group-margin-bottom: 0;
    --input-height-base: 30px;
    --input-height-lg: var(--input-height-base);
    --input-height-sm: var(--input-height-base);
    --input-vertical-padding: 7px;
    --input-horizontal-padding: 12px;
    --input-padding-horizontal: calc(var(--control-padding-horizontal) - 1px);
    --input-padding-horizontal-base: var(--input-padding-horizontal);
    --input-padding-horizontal-sm: calc(var(--control-padding-horizontal-sm) - 1px);
    --input-padding-horizontal-lg: var(--input-padding-horizontal);
    --input-padding-vertical-base: 4px;
    --input-padding-vertical-sm: 1px;
    --input-padding-vertical-lg: 6px;
    --input-border-width: var(--border-width-base);
    --input-border-top-width: var(--border-width-base);
    --input-border-right-width: var(--border-width-base);
    --input-border-bottom-width: var(--border-width-base);
    --input-border-left-width: var(--border-width-base);
    --input-outline-offset: 0 0;
    /* Label */
    --label-margin: 10px;
    /* Textarea */
    --textarea-minheight: 120px;
    --textarea-vertical-padding: 10px;
    --textarea-horizontal-padding: 12px;
    /* Checkbox */
    --checkbox-size: 16px;
    --checkbox-size-big: 24px;
    --checkbox-inner-size-base: 8px;
    --checkbox-inner-size-big-base: 12px;
    /* RadioButton */
    --radio-size-big: 24px;
    --radio-inner-size-big: 12px;
    --radio-button-inner-size-base: 8px;
    /* Pagination */
    --pagination-alignment: center;
    --pagination-size: 20px;
    /* ProgressBar */
    --progress-bar-step-radius: 32px;
    --progress-bar-line-width: 2px;
    --progress-bar-small-step-radius: 16px;
    --progress-bar-small-step-width: 44px;
    --progress-bar-small-line-width: 1px;
    --progress-bar-small-paddting-top: 9px;
    --progress-bar-shadow-color: rgba(76, 132, 118, 0.5);
    --progress-bar-type-bars-height: 3px;
    --progress-bar-type-bars-step-width: 50px;
    /* Select */
    --select-min-width: 100px;
    --select-max-height: 300px;
    --select-inner-indent: 20px;
    /* Toggle */
    --switch-color: var(--accent-default);
    --switch-circle-width: 16px;
    --switch-duration: 0.2s;
    --toggle-width: 40px;
    --toggle-height: 20px;
    --toggle-border-radius: 100px;
    --toggle-padding: 3px;
    /* FooterLarge */
    --footer-indent-base: 30px;
    --footer-logo-width: 100px;
    /* Grid */
    --container-max-with: 1202px;
    --row-margin-bottom: 30px;
    /* Header */
    --header-logo-width: 65px;
    --header-height: 80px;
    /* Modal */
    --modal-title-color: #25282e;
    --modal-padding: 30px;
    --modal-content-margin: 20px;
    /* Search */
    --search-height: 50px;
    --search-indents: var(--indent-base) 22px var(--indent-base) 62px;
    --search-dropdown-height: 250px;
    --search-dropdown-item-height: 40px;
    --search-dropdown-indents: 9px 20px;
    /* Tabs */
    --tabs-ink-bar-height: 5px;
    --tabs-extra-item-margin: 5px 5px 5px 15px;
    /* Navigation */
    --navigation-width: 240px;
    --navigation-padding: 40px;
    --navigation-border-width: 1px;
    --navigation-link-marker-size: 8px;
    --navigation-close-button-size: 16px;
    /* Table */
    --table-cell-expand-size: 34px;
    --table-cell-vertical-indents: 19px;
    --table-cell-horizontal-padding: 5px;
    --table-cell-horizontal-indents: 8px;
    --table-sort-width: 22px;
    --table-filter-width: 20px;
    --table-dropdown-spacing: 26px;
    --table-mini-cell-vertical-indents: 5px;
    --table-mini-cell-horizontal-indents: 8px;
    /* Tags */
    --tags-border-color: var(--primary-light);
    --tags-border-radius: 5px;
    --tags-indent: 5px 9px 5px 0;
    --tags-indent-inner: 4px 11px;
    --tags-indent-button: 4px;
    /* TabbedNavigation */
    --tabbed-navigation-links-margin: 40px;
    --tabbed-navigation-margin: var(--padding-base) 0;
    /* Slider */
    --slider-rail-height: 3px;
    --slider-dot-width: 2px;
    --slider-dot-height: 15px;
    --slider-dot-margin-top: 10px;
    --slider-handle-size: 18px;
    --slider-mark-text-max-width: 150px;
    /* Breadcrumbs */
    --breadcrumbs-margin: 10px;
    --breadcrumb-item-size: 10px;

    /*z-index list, order by `z-index`*/
    --zindex-table-fixed: auto;
    --zindex-affix: 10;
    --zindex-back-top: 10;
    --zindex-badge: 10;
    --zindex-picker-panel: 10;
    --zindex-popup-close: 10;
    --zindex-modal: 1000;
    --zindex-modal-mask: 1000;
    --zindex-message: 1010;
    --zindex-notification: 1010;
    --zindex-popover: 1030;
    --zindex-dropdown: 1050;
    --zindex-picker: 1050;
    --zindex-tooltip: 1060;

    --modal-footer-padding-vertical: 10px;
    --modal-footer-padding-horizontal: 16px;

    /*Media queries breakpoints*/
    /*Extra small screen / phone*/
    --screen-xs: 480px;
    --screen-xs-min: var(--screen-xs);

    /*// Small screen / tablet*/
    --screen-sm: 576px;
    --screen-sm-min: var(--screen-sm);

    /*// Medium screen / desktop*/
    --screen-md: 768px;
    --screen-md-min: var(--screen-md);

    /*// Large screen / wide desktop*/
    --screen-lg: 992px;
    --screen-lg-min: var(--screen-lg);

    /*// Extra large screen / full hd*/
    --screen-xl: 1200px;
    --screen-xl-min: var(--screen-xl);

    /*// Extra extra large screen / large desktop*/
    --screen-xxl: 1600px;
    --screen-xxl-min: var(--screen-xxl);

    /*Upload*/
    --upload-picture-card-size: 104px;
    --upload-picture-card-border-style: var(--border-style-base);

    /*Footer*/
    --footer-container-padding: 29px 70px;

    /* BarChart */
    --bar-chart-color-0: var(--accent-default);
    --bar-chart-color-1: var(--accent-lighter);
    --bar-chart-color-2: var(--accent-light);
    --bar-chart-color-3: var(--accent-dark);
    --bar-chart-color-4: var(--secondary-default);
    --bar-chart-color-5: var(--primary-lighter);
    --bar-chart-color-6: var(--primary-default);
    --bar-chart-color-7: var(--positive-lighter);
    --bar-chart-color-8: var(--positive-default);
    --bar-chart-color-9: var(--positive-darker);
}
