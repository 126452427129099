.Highcharts-bar-chart .highcharts-axis-line {
    stroke: var(--primary-default);
}

.Highcharts-bar-chart .highcharts-color-0.highcharts-point {
    fill: var(--bar-chart-color-0);
    stroke: var(--bar-chart-color-0);
}

.Highcharts-bar-chart .highcharts-color-1.highcharts-point {
    fill: var(--bar-chart-color-1);
    stroke: var(--bar-chart-color-1);
}

.Highcharts-bar-chart .highcharts-color-2.highcharts-point {
    fill: var(--bar-chart-color-2);
    stroke: var(--bar-chart-color-2);
}

.Highcharts-bar-chart .highcharts-color-3.highcharts-point {
    fill: var(--bar-chart-color-3);
    stroke: var(--bar-chart-color-3);
}

.Highcharts-bar-chart .highcharts-color-4.highcharts-point {
    fill: var(--bar-chart-color-4);
    stroke: var(--bar-chart-color-4);
}

.Highcharts-bar-chart .highcharts-color-5.highcharts-point {
    fill: var(--bar-chart-color-5);
    stroke: var(--bar-chart-color-5);
}

.Highcharts-bar-chart .highcharts-color-6.highcharts-point {
    fill: var(--bar-chart-color-6);
    stroke: var(--bar-chart-color-6);
}

.Highcharts-bar-chart .highcharts-color-7.highcharts-point {
    fill: var(--bar-chart-color-7);
    stroke: var(--bar-chart-color-7);
}

.Highcharts-bar-chart .highcharts-color-8.highcharts-point {
    fill: var(--bar-chart-color-8);
    stroke: var(--bar-chart-color-8);
}

.Highcharts-bar-chart .highcharts-color-9.highcharts-point {
    fill: var(--bar-chart-color-9);
    stroke: var(--bar-chart-color-9);
}

.Highcharts-bar-chart .highcharts-point {
    width: 25px;
}

.Highcharts-bar-chart .highcharts-background {
    fill: transparent;
}

.Highcharts-bar-chart .highcharts-axis-labels {
    font-family: var(--body-font-family);
}

.Highcharts-bar-chart .highcharts-axis-labels .Form-control-label {
    margin-bottom: 5px;
}

.Highcharts-bar-chart .highcharts-axis-labels .bar-chart__label {
    min-width: 100px;
    line-height: 22px;
}

.Highcharts-bar-chart .highcharts-axis-labels > span {
    padding-right: 10px;
    color: var(--primary-default);
    font-size: 1.6rem;
    line-height: 1.375;
}
