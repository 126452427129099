.Avatar {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: var(--avatar-default-size, 32px);
    height: var(--avatar-default-size, 32px);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: var(--avatar-font-size);
    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    vertical-align: middle;
    list-style: none;
    white-space: nowrap;
    text-align: center;
    line-height: var(--avatar-default-size, 32px);
}

.Avatar.avatar-square,
.Avatar.avatar-circle {
    color: var(--bright);
    background: var(--secondary-light);
}

.Avatar.avatar-image {
    background: transparent;
}

.Avatar.avatar-circle {
    border-radius: 50%;
}

.Avatar.avatar-square {
    border-radius: var(--border-radius-base);
}

.Avatar .avatar-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Avatar .avatar-content > img {
    display: block;
    width: 100%;
    height: 100%;
}

.Avatar.avatar-lg {
    width: var(--avatar-large-size, 40px);
    height: var(--avatar-large-size, 40px);
    line-height: var(--avatar-large-size, 40px);
}

.Avatar.avatar-sm {
    width: var(--avatar-small-size, 24px);
    height: var(--avatar-small-size, 24px);
    line-height: var(--avatar-small-size, 24px);
}