.button {
    position: relative;
    display: inline-block;
    height: var(--btn-height-base);
    padding: var(--btn-vertical-padding) var(--btn-horizontal-padding);
    min-width: var(--btn-width-base);
    box-shadow: none;
    border: 1px solid var(--primary-lighter);
    outline: 0;
    color: var(--primary-default);
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.125;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    touch-action: manipulation;
    @mixin transition;
}

.button-small {
    height: var(--btn-height-sm);
    min-width: calc(var(--btn-width-base) - 50px);
    padding: var(--btn-vertical-padding-sm) var(--btn-horizontal-padding-sm);
    font-size: 1.4rem;
    line-height: 1.142;
}

.button-small .loader-wrapper .loader {
    width: 16px;
    height: 16px;
}

/* Button primary */
.button-primary {
    background-color: var(--accent-default);
    border-color: var(--accent-default);
    color: var(--white);
}

.button-primary.button:hover,
.button-primary.button.button-pressed:hover {
    background-color: var(--btn-primary-hover-bg);
    border-color: var(--accent-dark);
    color: var(--white);
}

.button-primary:focus {
    background-color: var(--accent-default);
    border-color: var(--accent-default);
}

.button-primary.button-pressed,
.button-primary.button-pressed:active,
.button-primary:active,
.button-primary.active {
    background-color: var(--accent-darker);
    border-color: var(--accent-darker);
    color: var(--white);
}

.button-primary.button-disabled,
.button-primary.button-disabled:hover,
.button-primary.button-disabled:focus,
.button-primary.button-disabled:active,
.button-primary.button-disabled.active,
.button-primary.button[disabled],
.button-primary.button[disabled]:hover,
.button-primary.button[disabled]:focus,
.button-primary.button[disabled]:active,
.button-primary.button[disabled].active,
.button-primary.button.disabled,
.button-primary.button.disabled:hover,
.button-primary.button.disabled:focus,
.button-primary.button.disabled:active,
.button-primary.button.disabled.active {
    background-color: var(--secondary-lighter);
    border-color: var(--primary-lighter);
    color: var(--primary-light);
}

/* Button secondary */
.button-secondary {
    background-color: transparent;
    border-color: var(--primary-lighter);
    color: var(--primary-default);
}

.button-secondary.button:hover {
    background-color: var(--btn-secondary-hover-bg);
    border-color: var(--primary-light);
    color: var(--primary-default);
}

.button-secondary.button:focus {
    border-color: var(--primary-lighter);
    color: var(--primary-default);
    background-color: var(--white);
}

.button-secondary.button.button-pressed:hover {
    border-color: var(--primary-light);
    background-color: var(--white);
}

.button-secondary.button.button-pressed,
.button-secondary.button.button-pressed:active,
.button-secondary.button:active,
.button-secondary.button.active {
    background-color: var(--secondary-lighter);
    border-color: var(--primary-light);
    color: var(--primary-default);
}

.button-secondary.button-disabled,
.button-secondary.button-disabled:hover,
.button-secondary.button-disabled:focus,
.button-secondary.button-disabled:active,
.button-secondary.button-disabled.active,
.button-secondary.button[disabled],
.button-secondary.button[disabled]:hover,
.button-secondary.button[disabled]:focus,
.button-secondary.button[disabled]:active,
.button-secondary.button[disabled].active,
.button-secondary.button.disabled,
.button-secondary.button.disabled:hover,
.button-secondary.button.disabled:focus,
.button-secondary.button.disabled:active,
.button-secondary.button.disabled.active {
    border-color: var(--primary-lighter);
    color: var(--btn-secondary-disabled-color);
    opacity: 0.5;
    background-color: var(--white-darker);
}

/* Button danger */
.button-danger {
    background-color: var(--negative-default);
    border-color: var(--negative-default);
    color: var(--white);
}

.button-danger.button:hover,
.button-danger.button.button-pressed:hover {
    background-color: var(--negative-dark);
    border-color: var(--negative-dark);
}

.button-danger.button:focus {
    background-color: var(--negative-default);
    border-color: var(--negative-default);
    color: var(--white);
}

.button-danger.button.button-pressed,
.button-danger.button.button-pressed:active,
.button-danger.button:active,
.button-danger.button.active {
    background-color: var(--negative-darker);
    border-color: var(--negative-darker);
    color: var(--white);
}

.button-danger.button-disabled,
.button-danger.button-disabled:hover,
.button-danger.button-disabled:focus,
.button-danger.button-disabled:active,
.button-danger.button-disabled.active,
.button-danger.button[disabled],
.button-danger.button[disabled]:hover,
.button-danger.button[disabled]:focus,
.button-danger.button[disabled]:active,
.button-danger.button[disabled].active,
.button-danger.button.disabled,
.button-danger.button.disabled:hover,
.button-danger.button.disabled:focus,
.button-danger.button.disabled:active,
.button-danger.button.disabled.active {
    background-color: var(--negative-default);
    border-color: var(--negative-default);
    color: var(--white);
    opacity: 0.5;
}

.button:focus {
    @mixin focus;
}

.button.button-disabled,
.button.button-disabled:hover,
.button.button-disabled:focus,
.button.button-disabled:active,
.button.button-disabled.active,
.button[disabled],
.button[disabled]:hover,
.button[disabled]:focus,
.button[disabled]:active,
.button[disabled].active,
.button.disabled,
.button.disabled:hover,
.button.disabled:focus,
.button.disabled:active,
.button.disabled.active {
    cursor: not-allowed;
}
