.ant-tooltip {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--text-color);
    font-size: var(--font-size-base);
    font-variant: var(--font-variant-base);
    list-style: none;
    line-height: var(--line-height-base);
    font-feature-settings: var(--font-feature-settings-base);
}

.ant-tooltip-hidden {
    display: none;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
    padding-bottom: var(--tooltip-distance);
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
    padding-left: var(--tooltip-distance);
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
    padding-top: var(--tooltip-distance);
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
    padding-right: var(--tooltip-distance);
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    bottom: calc(var(--tooltip-distance) - var(--tooltip-arrow-width));
    border-width: var(--tooltip-arrow-width) var(--tooltip-arrow-width) 0;
    border-top-color: var(--tooltip-border-color-base);
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
    left: 50%;
    margin-left: calc(var(--tooltip-arrow-width) * -1);
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
    left: 16px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    right: 16px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    left: calc(var(--tooltip-distance) - var(--tooltip-arrow-width));
    border-width: var(--tooltip-arrow-width) var(--tooltip-arrow-width) var(--tooltip-arrow-width) 0;
    border-right-color: var(--tooltip-border-color-base);
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
    top: 50%;
    margin-top: calc(var(--tooltip-arrow-width) * -1);
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    top: 8px;
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    bottom: 8px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    right: calc(var(--tooltip-distance) - var(--tooltip-arrow-width));
    border-width: var(--tooltip-arrow-width) 0 var(--tooltip-arrow-width) var(--tooltip-arrow-width);
    border-left-color: var(--tooltip-border-color-base);
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
    top: 50%;
    margin-top: calc(var(--tooltip-arrow-width) * -1);
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    top: 8px;
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    bottom: 8px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    top: calc(var(--tooltip-distance) - var(--tooltip-arrow-width));
    border-width: 0 var(--tooltip-arrow-width) var(--tooltip-arrow-width);
    border-bottom-color: var(--tooltip-border-color-base);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
    left: 50%;
    margin-left: calc(var(--tooltip-arrow-width) * -1);
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
    left: 16px;
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 16px;
}
