.Accordion {
    width: 100%;
    border-top: 1px solid var(--secondary-lighter);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: var(--bright);
    color: var(--primary-default);
    list-style: none;
    font-size: 1.4rem;
    line-height: 1.5;
    border-bottom: 0;
    border-radius: 2px;
}
