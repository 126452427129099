.Footer__social {
    white-space: nowrap;
}

.Footer__social a {
    text-decoration: none;
}

.Footer__social a:hover {
    color: var(--primary-lighter);
}
