.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
    padding-bottom: var(--tooltip-distance);
}

.rc-tooltip-placement-top > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-topRight > .rc-tooltip-content > .rc-tooltip-arrow {
    bottom: 6px;
    border-top-color: transparent;
    border-right-color: var(--background-content);
    border-bottom-color: var(--background-content);
    border-left-color: transparent;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    bottom: calc(var(--tooltip-distance) - 23px);
    box-shadow: 1px 1px 0 var(--primary-lighter) !important;

}

.rc-tooltip-placement-top .rc-tooltip-arrow .rc-tooltip-content,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow .rc-tooltip-content,
.rc-tooltip-placement-topRight .rc-tooltip-arrow .rc-tooltip-content {
    transform: translateY(calc(-15px / 2)) rotate(45deg);
}

.rc-tooltip-placement-top > .rc-tooltip-content > .rc-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
    left: var(--popover-arrow-offset-horizontal);
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: var(--popover-arrow-offset-horizontal);
}

.rc-tooltip-placement-top .rc-tooltip-arrow:after,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow:after,
.rc-tooltip-placement-topRight .rc-tooltip-arrow:after {
    bottom: calc(var(--tooltip-distance) - var(--tooltip-arrow-width));
    border-right-width: var(--tooltip-arrow-width);
    border-left-width: var(--tooltip-arrow-width);
    border-top-width: calc(var(--tooltip-arrow-width) + 1px);
    border-bottom-width: 0;
    border-top-color: var(--white);
    top: calc(calc(var(--tooltip-arrow-width) + 2px) * -1);
    left: calc(var(--tooltip-arrow-width) * -1);
}