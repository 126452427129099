.Title {
    color: var(--primary-default);
}

.Title a {
    color: inherit;
}

.Title a:hover {
    color: var(--primary-dark);
}

.Title .Icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    font-size: 12px;
    line-height: inherit;
}

.Title .Content-box>.Title :first-child {
    margin: 0 var(--padding-base);
    padding: 0 var(--padding-base) var(--padding-base);
}

.Title .Content-box>.Title:first-child+.ant-tabs,
.Title .Content-box>.Title:first-child+.calendar,
.Title .Content-box>.Title:first-child+.table-wrapper-no-header {
    margin-top: -22px;
}

.Title .Content-box_title>.Title :first-child {
    border-bottom: 1px solid var(--secondary-lighter);
}

/* H1 */
h1,
.title-1 {
    font-family: var(--accent-font-family);
    font-weight: var(--accent-font-weight);
    margin-bottom: var(--title-1-margin-bottom);
    @mixin font 28, 34;
}

/* H2 */
h2,
.title-2 {
    font-family: var(--accent-font-family);
    font-weight: var(--accent-font-weight);
    @mixin font 22, 28;
    margin-bottom: var(--title-2-margin-bottom);
}

/* H3 */
h3,
.title-3 {
    @mixin font 18, 24;
    font-family: var(--body-font-family);
    font-weight: normal;
    margin-bottom: var(--title-3-margin-bottom);
}

/* H4 */
h4,
.title-4 {
    @mixin font 16, 22;
    font-family: var(--body-font-family);
    font-weight: normal;
    margin-bottom: var(--title-4-margin-bottom);
}
