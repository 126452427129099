.TextInput {
    padding: var(--input-vertical-padding) var(--input-horizontal-padding);
    background-color: transparent;
    border: 1px solid var(--primary-lighter);
    font-size: 1.4rem;
    color: var(--input-color);
    line-height: 1.14285714;
}

.TextInput-affix-wrapper .TextInput-prefix + input.TextInput {
    padding-left: calc(var(--margin-base) * 2);
}

.TextInput-affix-wrapper .rc-tooltiped-text {
    margin-right: 0;
}

.TextInput-affix-wrapper .TextInput-suffix {
    right: 10px;
    color: var(--primary-default);
}

.form-group_invalid .TextInput-affix-wrapper .TextInput-suffix {
    color: var(--negative-default);
}

.form-group_disabled .TextInput-affix-wrapper .TextInput-suffix {
    color: var(--primary-light);
}

.form-group_disabled .TextInput-affix-wrapper {
    opacity: 0.5;
    pointer-events: none;
}

.TextInput-affix-wrapper:hover .TextInput:not(.TextInput-disabled) {
    border-color: var(--primary-light);
}

.TextInput:hover {
    border-color: var(--primary-light);
}

.TextInput:focus,
.TextInput_focused,
.TextInput_focused .TextInput-input,
.TextInput_focused .TextInput-group:hover {
    box-shadow: none;
}

.TextInput_invalid input {
    border-color: var(--negative-default);
}

.TextInput_invalid .rc-tooltiped-text {
    color: var(--negative-default);
}

.TextInput_invalid:hover,
.TextInput_invalid.TextInput-affix-wrapper:hover .TextInput:not(.TextInput-disabled) {
    border-color: var(--negative-dark);
}

.TextInput[disabled],
.form-group_disabled .TextInput,
.TextInput_disabled {
    color: var(--primary-default);
    box-shadow: none;
    background: transparent;
}

.TextInput-group {
    position: relative;
    display: table;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 1px solid var(--primary-lighter);
    border-radius: var(--border-radius-base);
    font-variant: tabular-nums;
    list-style: none;
    line-height: 1.5;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    text-align: start;

}

.form-group_disabled .TextInput-group-wrapper .TextInput-group {
    cursor: not-allowed;
}

.TextInput_invalid .TextInput-group {
    border-color: var(--negative-default);
}

.TextInput-group:hover {
    border-color: var(--primary-light);
}

.form-group_disabled .TextInput-group:hover {
    border-color: var(--primary-lighter);
}

.TextInput_invalid .TextInput-group:hover {
    border-color: var(--negative-dark);
}

.TextInput-group input {
    border: 0;
}

.TextInput-group input:hover {
    border: 0;
}

.TextInput-group-addon {
    position: relative;
    padding: 0 10px;
    color: var(--primary-light);
    border: 0;
    background-color: transparent;
}

.TextInput-group-addon button {
    font-size: 1.4rem;
}

.TextInput .addon-after-border:before {
    position: absolute;
    top: 6px;
    left: 0;
    z-index: 1;
    display: inline-block;
    width: 1px;
    height: calc(var(--input-height-base) - 12px);
    content: '';
    background-color: var(--input-border-color);
}

.TextInput:focus {
    outline-style: auto;
    outline-color: Highlight;
    outline: 5px auto -webkit-focus-ring-color;
}

.with-select-box .select__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    padding: 0;
    text-align: center;
    text-decoration: none;
}

.TextInput-group-addon,
.TextInput-group-wrap,
.TextInput-group > .TextInput {
    display: table-cell;
}

.TextInput-group-addon:not(:first-child):not(:last-child),
.TextInput-group-wrap:not(:first-child):not(:last-child),
.TextInput-group > .TextInput:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.TextInput-group-addon,
.TextInput-group-wrap {
    width: 1px;
    vertical-align: middle;
    white-space: nowrap;
}

.TextInput-group-wrap > * {
    display: block !important;
}

.TextInput-group .TextInput {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;
}

.TextInput-group .TextInput:focus {
    z-index: 1;
    border-right-width: 1px;
}

.TextInput-group .TextInput:hover {
    z-index: 1;
    border-right-width: 1px;
}

.TextInput-group .TextInput-affix-wrapper {
    float: left;
    width: 100%;
}

.TextInput-group-wrapper {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: start;
}

.TextInput-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    line-height: 1.5;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    text-align: start;
}

.TextInput-affix-wrapper .TextInput {
    position: relative;
    text-align: inherit;
    min-height: 100%;
}

.TextInput-affix-wrapper .TextInput-prefix,
.TextInput-affix-wrapper .TextInput-suffix {
    position: absolute;
    top: 50%;
    z-index: 2;
    line-height: 0;
    transform: translateY(-50%);
}

.TextInput-affix-wrapper .TextInput-prefix :not(.anticon),
.TextInput-affix-wrapper .TextInput-suffix :not(.anticon) {
    line-height: 1.5;
}

.TextInput-affix-wrapper .TextInput-prefix {
    left: 12px;
}

.TextInput-password-icon {
    cursor: pointer;
    transition: all 0.3s;
}

.TextInput-affix-wrapper .TextInput:not(:last-child) {
    padding-right: 30px;
}
