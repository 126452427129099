.FileItem {
    display: flex;
    align-items: center;
}

.FileItem .upload-list-item-content {
    display: inline-block;
    overflow: hidden;
    height: 100%;
    padding: 0 8px 0 4px;
    color: var(--primary-default);
    white-space: nowrap;
    text-overflow: ellipsis;

}

.FileItem .action-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    padding: 3px;
    background: var(--negative-default);
    border-radius: 50%;
    font-size: 8px;

}

.FileItem .action-delete > svg > path {
    fill: var(--white);
}