.Info-box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: var(--infobox-top-padding) var(--infobox-left-padding) var(--infobox-bottom-padding) var(--infobox-right-padding);
    color: var(--primary-default);
    border-radius: var(--border-radius-base);
    border: 1px solid var(--primary-lighter);
    background-color: var(--background-content);
}

.info-box_icon {
    padding-right: 10px;
    color: var(--accent-default);
}

.info-box_accent {
    background-color: var(--secondary-lighter);
}

.info-box_error span {
    color: var(--negative-default);
}
