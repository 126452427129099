.dropdown-inner .dropdown {
    border-right: none;
    border-radius: var(--dropdown-border-radius);
    overflow: hidden;
}

.dropdown-inner .dropdown .menu-item > * {
    color: var(--primary-default);
    line-height: 30px;
}

.dropdown-inner .dropdown .menu-item:not(:last-child) {
    margin-bottom: 1px;
}

.dropdown-inner .dropdown .menu-item:hover {
    background-color: var(--accent-default);
    color: var(--bright);
}

.dropdown-inner .dropdown .menu-item:hover > * {
    color: var(--bright) !important;
}

.dropdown-inner .rc-tooltip-inner-content {
    padding: 0;
    box-shadow: 0 0 0 1px var(--primary-lighter);
    border-radius: var(--popover-inner-content-border-radius);
}
