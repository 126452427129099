.AutoComplete {
    position: relative;
    width: 100%;
    font-size: 1.6rem;
    box-sizing: content-box;
    border: 1px solid var(--primary-lighter);
    line-height: var(--search-height);
    background-color: var(--bright);
    border-radius: var(--border-radius-base);
}

.AutoComplete.AutoComplete__opened {
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
}

.AutoComplete .form-group input.TextInput {
    padding: var(--search-indents);
    height: var(--search-height);
    font-size: 1.6rem;
    line-height: 1.5;
    border-radius: 0;
}

.AutoComplete .form-group input.TextInput,
.AutoComplete .form-group .TextInput-group {
    border: none;
}

.AutoComplete .form-group input.TextInput:focus {
    outline: none;
}

.AutoComplete .search-icon {
    position: relative;
    left: 10px;
    color: var(--primary-light);
    font-size: 2rem;
}

.AutoComplete .AutoComplete-dropdown {
    position: absolute;
    z-index: 99;
    top: calc(100% + 1px);
    left: -1px;
    right: 0;
    overflow: auto;
    width: 100%;
    max-height: var(--search-dropdown-height);
    background-color: var(--bright);
    border: 1px solid var(--primary-lighter);
    border-top: 0;
    border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
    transform: scaleY(0);
    transform-origin: top;
    transition: var(--transition);
}

.AutoComplete .AutoComplete-dropdown.AutoComplete-dropdown__opened {
    transform: scaleY(1);
}

.AutoComplete .AutoComplete-dropdown .select__option .checkbox-wrapper {
    min-height: var(--search-dropdown-item-height);
    padding: var(--search-dropdown-indents);
    border-radius: 0;
    font-size: 1.6rem;
    line-height: 1.375;
    transition: var(--transition);
}

.AutoComplete .AutoComplete-dropdown .select__option:focus .checkbox-wrapper,
.AutoComplete .AutoComplete-dropdown .select__option:hover .checkbox-wrapper,
.AutoComplete .AutoComplete-dropdown .select__option-active .checkbox-wrapper {
    background: var(--accent-default);
    color: var(--white);
}
