/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-breadcrumb {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    line-height: var(--line-height-base);
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    color: var(--breadcrumb-color-base);
    font-size: var(--font-size-base);
}
.ant-breadcrumb .Icon {
    font-size: var(--font-size-base);
}
.ant-breadcrumb a {
    color: var(--breadcrumb-color-base);
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}
.ant-breadcrumb a:hover {
    color: var(--breadcrumb-color-hover);
}
.ant-breadcrumb > span:last-child {
    color: var(--breadcrumb-color-active);
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
    display: none;
}
.ant-breadcrumb-separator {
    margin: 0 8px;
    color: var(--breadcrumb-color-base);
}
.ant-breadcrumb-link > .Icon + span {
    margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .Icon {
    margin-left: 4px;
}
