.Highcharts-column-chart .highcharts-plot-line {
    stroke: var(--primary-default);
}

.Highcharts-column-chart .highcharts-background {
    fill: transparent;
}

.Highcharts-column-chart .highcharts-series-0 .highcharts-point {
    fill: var(--positive-default);
}

.Highcharts-column-chart .highcharts-series-1 .highcharts-point {
    fill: var(--negative-default);
}

.Highcharts-column-chart .highcharts-grid-line,
.Highcharts-column-chart .highcharts-axis-line {
    display: none;
}

.Highcharts-column-chart .highcharts-stack-labels > span {
    visibility: inherit !important;
}

.Highcharts-column-chart .highcharts-stack-labels > span .top {
    top: -10px;
    position: relative;
}

.Highcharts-column-chart .highcharts-stack-labels > span .bottom {
    bottom: -10px;
    position: relative;
}

.Highcharts-column-chart .highcharts-stack-labels > span .label {
    color: var(--primary-default);
    font-size: 1.6rem;
    line-height: 1.375;
    font-family: var(--body-font-family);
    font-weight: 400;
}
