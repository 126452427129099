@import './styles/placements/bottom.css';
@import './styles/placements/right.css';
@import './styles/placements/top.css';
@import './styles/placements/left.css';

.Popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    box-sizing: border-box;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    font-feature-settings: 'tnum';
    font-weight: normal;
    white-space: normal;
    text-align: left;
    cursor: auto;
    user-select: text;
}

.rc-tooltip {
    box-sizing: border-box;
    color: var(--text-color);
    font-size: var(--font-size-base);
    font-variant: var(--font-variant-base);
    list-style: none;
    line-height: var(--line-height-base);
    font-feature-settings: var(--font-feature-settings-base);
}

.rc-tooltip-hidden {
    display: none;
}

.rc-tooltip-inner {
    background-color: var(--background-content);
    border-radius: var(--border-radius-base);
    background-clip: padding-box;
    box-shadow: none;

}

.rc-tooltip-arrow {
    z-index: 1062;
    position: absolute;
    display: block;
    overflow: hidden;
    width: var(--tooltip-arrow-width);
    height: var(--tooltip-arrow-width);
    background: transparent;
    border-style: solid;
    border-width: var(--popover-arrow-border-width);
    box-shadow: -1px -1px 0 var(--primary-lighter) !important;
    pointer-events: none;
    transform: rotate(45deg);
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow,
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    transform: translateX(calc(1px - var(--tooltip-arrow-width))) rotate(45deg);
}

.rc-tooltip-placement-bottom .rc-tooltip-inner,
.rc-tooltip-placement-bottomLeft .rc-tooltip-inner,
.rc-tooltip-placement-bottomRight .rc-tooltip-inner,
.rc-tooltip-placement-top .rc-tooltip-inner,
.rc-tooltip-placement-topLeft .rc-tooltip-inner,
.rc-tooltip-placement-topRight .rc-tooltip-inner {
    transform: translateX(var(--tooltip-arrow-width));
}

.rc-tooltip-inner-content {
    padding: 0;
    box-shadow: 0 0 0 1px var(--primary-lighter);
    border-radius: var(--popover-inner-content-border-radius);
}

.rc-tooltip-arrow:after {
    position: absolute;
    z-index: 1061;
    display: block;
    overflow: visible;
    width: 0;
    height: 0;
    background: none;
    content: '';
    border-style: solid;
    border-color: transparent;
}