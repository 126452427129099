.form-control-message {
    display: none;
    color: var(--negative-default);
}

.textarea + .form-control-message {
    white-space: normal;
}

.form-group_invalid .form-control-message {
    display: inline-block;
    color: var(--negative-default);
}
