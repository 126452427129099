.Footer {
    background-color: var(--background-footer);
    display: flex;
    justify-content: stretch;
    align-items: stretch;
}

.Footer__nav {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: var(--footer-indent-base) 0 0;
    color: var(--bright);
    font-weight: bold;
    letter-spacing: 0.25px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 2;
}

@media only screen and (max-width: 992px) {
    .Footer__nav {
        flex-wrap: wrap;
    }
}

.Footer__nav nav {
    min-width: calc(100% / 6);
    margin-bottom: var(--footer-indent-base);
}

@media only screen and (max-width: 992px) {
    .Footer__nav nav {
        min-width: calc(100% / 3);
    }
}

.Footer__nav a {
    display: block;
    font-weight: normal;
    color: var(--bright);
}

.Footer__nav a:first-child {
    margin-top: 10px;
}

.Footer__nav a:hover {
    text-decoration: underline;
}

.Footer__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--footer-indent-base) 0;
    color: var(--primary-light);
    letter-spacing: 0.22px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.57142857;
}

.Footer__nav + .Footer__bottom {
    border-top: 1px solid var(--primary-light);
}

.Footer__bottom a {
    color: var(--primary-light);
    text-decoration: underline;
}

.Footer__bottom a:not(:first-child) {
    margin-left: 20px;
}

.Footer__bottom a:hover {
    text-decoration: none;
}

.Footer__bottom img {
    max-width: var(--footer-logo-width);
}

.Footer__bottom .Icon {
    font-size: 16px;
}

.Footer_mini div.Footer__bottom.container {
    padding: var(--footer-container-padding);
}
