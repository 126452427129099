.radio-group {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--text-color);
    font-size: var(--font-size-base);
    font-variant: var(--font-variant-base);
    list-style: none;
    line-height: var(--line-height-base);
    -webkit-font-feature-settings: var(--font-feature-settings-base);
    font-feature-settings: var(--font-feature-settings-base);
}

.radio-wrapper {
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--text-color);
    font-size: var(--font-size-base);
    font-variant: var(--font-variant-base);
    list-style: none;
    line-height: var(--line-height-base);
    -webkit-font-feature-settings: var(--font-feature-settings-base);
    font-feature-settings: var(--font-feature-settings-base);
    margin-right: 8px;
    white-space: nowrap;
    cursor: pointer;
}

.radio {
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: sub;
    margin: 0;
    padding: 0;
    color: var(--text-color);
    font-size: var(--font-size-base);
    font-variant: var(--font-variant-base);
    outline: none;
    list-style: none;
    line-height: var(--line-height-base);
    -webkit-font-feature-settings: var(--font-feature-settings-base);
    font-feature-settings: var(--font-feature-settings-base);
    white-space: nowrap;
    cursor: pointer;
}

.radio-wrapper:hover .radio,
.radio:hover .radio-inner,
.radio-input:focus + .radio-inner {
    border-color: var(--radio-dot-color);
}

.radio-input:focus + .radio-inner {
    -webkit-box-shadow: var(--radio-button-box-shadow);
    box-shadow: var(--radio-button-box-shadow);
}

.radio-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    border: 1px solid var(--radio-dot-color);
    content: '';
    border-radius: 50%;
    animation: antRadioEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.radio:hover:after,
.radio-wrapper:hover .radio:after {
    visibility: visible;
}

.radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: var(--radio-size);
    height: var(--radio-size);
    background-color: var(--radio-button-bg);
    border-color: var(--border-color-base);
    border-style: solid;
    border-width: 1px;
    border-radius: 100px;
    -webkit-transition: all var(--radio-duration);
    transition: all var(--radio-duration);
}

.radio-inner:after {
    --radio-dot-size: calc(var(--radio-size) - 8px);

    position: absolute;
    top: calc(calc(var(--radio-size) - var(--radio-dot-size)) / 2 - 1px);
    left: calc(calc(var(--radio-size) - var(--radio-dot-size)) / 2 - 1px);
    display: table;
    width: var(--radio-dot-size);
    height: var(--radio-dot-size);
    background-color: var(--radio-dot-color);
    border-top: 0;
    border-left: 0;
    border-radius: var(--radio-dot-size);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    content: ' ';
    -webkit-transition: all var(--radio-duration) var(--ease-in-out-circ);
    transition: all var(--radio-duration) var(--ease-in-out-circ);
}

.radio-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}

.radio-checked .radio-inner {
    border-color: var(--radio-dot-color);
}

.radio-checked .radio-inner::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -webkit-transition: all var(--radio-duration) var(--ease-in-out-circ);
    transition: all var(--radio-duration) var(--ease-in-out-circ);
}

.radio-disabled .radio-inner {
    background-color: var(--input-disabled-bg-color);
    border-color: var(--border-color-base) !important;
    cursor: not-allowed;
}

.radio-disabled .radio-inner:after {
    background-color: rgba(var(--black-rgb), 0.2);
}

.radio-disabled .radio-input {
    cursor: not-allowed;
}

.radio-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

span.radio + * {
    padding-right: 8px;
    padding-left: 8px;
}

.radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: var(--btn-height-base);
    margin: 0;
    padding: 0 calc(var(--padding-md) - 1px);
    color: var(--radio-button-color);
    background: var(--radio-button-bg);
    border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
    line-height: calc(var(--btn-height-base) - 2px);
    border-top-width: calc(var(--border-width-base) + 0.02px);
    border-left: 0;
    cursor: pointer;
    -webkit-transition: color 0.3s, background 0.3s, border-color 0.3s;
    transition: color 0.3s, background 0.3s, border-color 0.3s;
}

.radio-button-wrapper a {
    color: var(--radio-button-color);
}

.radio-button-wrapper > .radio-button {
    display: block;
    width: 0;
    height: 0;
    margin-left: 0;
}

.radio-group-large .radio-button-wrapper {
    height: var(--input-height-lg);
    font-size: var(--font-size-lg);
    line-height: calc(var(--input-height-lg) - 2px);
}

.radio-group-small .radio-button-wrapper {
    height: var(--input-height-sm);
    padding: 0 calc(var(--control-padding-horizontal-sm) - 1px);
    line-height: calc(var(--input-height-sm) - 2px);
}

.radio-button-wrapper:not(:first-child):before {
    position: absolute;
    top: 0;
    left: -1px;
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--border-color-base);
    content: '';
}

.radio-button-wrapper:first-child {
    border-left: var(--border-width-base) var(--border-style-base) var(--border-color-base);
    border-radius: var(--border-radius-base) 0 0 var(--border-radius-base);
}

.radio-button-wrapper:last-child {
    border-radius: 0 var(--border-radius-base) var(--border-radius-base) 0;
}

.radio-button-wrapper:first-child:last-child {
    border-radius: var(--border-radius-base);
}

.radio-button-wrapper:hover {
    position: relative;
    color: var(--radio-dot-color);
}

.radio-button-wrapper:focus-within {
    outline: var(--radio-focused-outline);
}

.radio-button-wrapper .radio-inner,
.radio-button-wrapper input[type='checkbox'],
.radio-button-wrapper input[type='radio'] {
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
}

.radio-button-wrapper-checked {
    z-index: 1;
    color: var(--radio-dot-color);
    background: var(--radio-button-checked-bg);
    border-color: var(--radio-dot-color);
    box-shadow: -1px 0 0 0 var(--radio-dot-color);
}

.radio-button-wrapper-checked:before {
    background-color: var(--radio-dot-color) !important;
    opacity: 0.1;
}

.radio-button-wrapper-checked:first-child {
    border-color: var(--radio-dot-color);
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.radio-button-wrapper-checked:hover {
    color: var(--radio-button-hover-color);
    border-color: var(--radio-button-hover-color);
    -webkit-box-shadow: -1px 0 0 0 var(--radio-button-hover-color);
    box-shadow: -1px 0 0 0 var(--radio-button-hover-color);
}

.radio-button-wrapper-checked:active {
    color: var(--radio-button-active-color);
    border-color: var(--radio-button-active-color);
    -webkit-box-shadow: -1px 0 0 0 var(--radio-button-active-color);
    box-shadow: -1px 0 0 0 var(--radio-button-active-color);
}

.radio-button-wrapper-checked:focus-within {
    outline: 3px solid var(--radio-focused-outline);
}

.radio-group-solid .radio-button-wrapper-checked:not(.radio-button-wrapper-disabled) {
    color: var(--component-background);
    background: var(--radio-dot-color);
    border-color: var(--radio-dot-color);
}

.radio-group-solid .radio-button-wrapper-checked:not(.radio-button-wrapper-disabled):hover {
    color: var(--component-background);
    background: var(--radio-button-hover-color);
    border-color: var(--radio-button-hover-color);
}

.radio-group-solid .radio-button-wrapper-checked:not(.radio-button-wrapper-disabled):active {
    color: var(--component-background);
    background: var(--radio-button-active-color);
    border-color: var(--radio-button-active-color);
}

.radio-group-solid .radio-button-wrapper-checked:not(.radio-button-wrapper-disabled):focus-within {
    outline: 3px solid var(--radio-focused-outline);
}

.radio-button-wrapper-disabled {
    color: var(--disabled-color);
    background-color: var(--input-disabled-bg-color);
    border-color: var(--border-color-base);
    cursor: not-allowed;
}

.radio-button-wrapper-disabled:first-child,
.radio-button-wrapper-disabled:hover {
    color: var(--disabled-color);
    background-color: var(--input-disabled-bg-color);
    border-color: var(--border-color-base);
}

.radio-button-wrapper-disabled:first-child {
    border-left-color: var(--border-color-base);
}

.radio-button-wrapper-disabled.radio-button-wrapper-checked {
    color: var(--text-color-inverse);
    background-color: var(--radion-bt-wr-dis-checked);
    border-color: var(--border-color-base);
    -webkit-box-shadow: none;
    box-shadow: none;
}

@-webkit-keyframes antRadioEffect {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
        opacity: 0;
    }

}

@keyframes antRadioEffect {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
        opacity: 0;
    }

}

@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
    .radio {
        vertical-align: text-bottom;
    }
}

.radio {
    top: -0.04em;
}

.radio-wrapper {
    display: inline-flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 1.57142857;
}

.radio-wrapper-disabled {
    opacity: 0.5;
}

.radio-inner:after {
    transition: none;
}

.radio-checked .radio-inner {
    border-color: var(--primary-lighter);
}

.radio-checked .radio-inner:after {
    top: 50%;
    left: 50%;
    width: var(--radio-button-inner-size-base);
    height: var(--radio-button-inner-size-base);
    border: 0;
    background: url('../../assets/icons/radio.svg') 50% no-repeat;
    transform: translate(-50%, -50%);
    transition: none;
}

.radio-wrapper-disabled .radio-inner,
.radio-disabled .radio-inner {
    background-color: var(--white);
}

.radio-error .radio-inner {
    border-color: var(--negative-default);
}

.radio-label {
    color: var(--primary-default);
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.14285714;
}

.radio-description {
    color: var(--primary-light);
    margin-bottom: 0;
}

.radio-group {
    display: inline-flex;
    flex-direction: column;
}

.radio-group-horizontal {
    flex-direction: row;
}

.radio-group .radio-wrapper + .radio-wrapper {
    margin-left: 0;
    margin-top: 20px;
}

.radio-group-horizontal .radio-wrapper + .radio-wrapper {
    margin-left: calc(var(--margin-base) * 2);
    margin-top: 0;
}

.radio-wrapper + span,
.radio + span {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    padding: 0;
    white-space: normal;
}

.radio-wrapper + span > span,
.radio + span > span,
.radio-wrapper + span > p,
.radio + span > p {
    padding-left: calc(var(--margin-base) / 2);
}

.radio-wrapper + span > span:empty,
.radio + span > span:empty,
.radio-wrapper + span > p:empty,
.radio + span > p:empty {
    padding-left: 0;
}

.radio-wrapper:hover .radio-inner,
.radio:hover .radio-inner {
    border-color: var(--primary-light);
    background-color: var(--secondary-lighter);
}

.radio-wrapper-disabled:hover .radio-inner,
.radio-disabled:hover .radio-inner {
    background-color: var(--white);
}

.radio-input:focus + .radio-inner {
    box-shadow: 0 0 1px 2px var(--accent-default);
}

.radio-error:hover .radio-inner {
    border-color: var(--negative-dark);
}

.radio-wrapper.radio-big {
    align-items: center;
}

.radio-wrapper.radio-big .radio-inner {
    width: var(--radio-size-big);
    height: var(--radio-size-big);
}

.radio-wrapper.radio-big .radio-checked .radio-inner:after {
    width: var(--radio-inner-size-big);
    height: var(--radio-inner-size-big);
    background-size: var(--radio-inner-size-big);
}