.Form-control-label {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.4rem;
    color: var(--primary-default);
    line-height: 1.28571429;
    letter-spacing: 0.5px;
    font-family: var(--accent-font-family);
    font-weight: bold;
    margin-bottom: var(--label-margin);
}

.Form-control-label.required span:after {
    content: '*';
}

.Form-control-label .Icon {
    font-size: 10px;
    top: -3px;
    position: relative;
    display: inline-flex;
    padding: 5px;
    color: var(--primary-light);
}

.form-group_disabled .Form-control-label {
    color: var(--primary-default);
    opacity: 0.5;
}
