.Menu .menu-item {
    position: relative;
    overflow: visible;
    padding: calc(var(--margin-base) / 4) var(--margin-base);
    margin: 0;
    color: var(--primary-default);
    font-size: var(--dropdown-menu-font-size);
    line-height: 1.875;
    transition: all 0.3s ease 0s;
}

.Menu .menu-item > a {
    display: block;
}

.Menu .menu-item:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid var(--accent-default);
    transform: scaleY(0.0001);
    opacity: 0;
    content: '';
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.Menu .menu-item:not(:last-child):after {
    top: auto;
    left: 20px;
    bottom: -1px;
    display: inline-block;
    width: calc(100% - 40px);
    border-right: none;
    border-bottom: 1px solid var(--secondary-lighter);
    opacity: 1;
    -webkit-transform: none;
    transform: none;
}
