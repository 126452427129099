.Table-wrapper {
    position: relative;
    width: 100%;
    overflow-y: auto;
}

.Table-wrapper .pagination {
    padding: 30px 0 0;
    margin: 0;
    border-top: 1px solid var(--secondary-lighter);
}

.Table {
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: collapse;
}

.Table.Table-no-header thead {
    display: none;
}

/* Table scrollable */

.Table-wrapper.scrollable .Table-content {
    overflow-y: auto;
    display: block;
    width: 100%;
}

.Table-wrapper.scrollable .Table-content .Table {
    width: 100%;
    table-layout: fixed;
}

.Table-wrapper.scrollable > .Table {
    width: calc(100% - 16px);
}

/* Table head */

.Table thead > tr {
    background: transparent !important;
    border-bottom: 1px solid var(--secondary-lighter);
    transition: all 0.3s, height 0s;
}

.Table thead > tr th {
    position: relative;
    padding: 16px 0;
    color: var(--table-header-color);
    background: transparent;
    line-height: 1.25;
    font-weight: bold;
    min-width: 80px;
    white-space: nowrap;
    font-size: 1.6rem;
    border-bottom: none;
    transition: all 130ms ease 0s;
}

.Table thead > tr th > .header-column {
    padding-right: var(--table-cell-horizontal-padding);
}

.Table thead > tr th.column-sort:hover,
.Table thead > tr th.column-filter:hover {
    color: var(--table-header-hover-color);
}

.Table thead > tr th:not(:last-child) {
    padding-right: var(--table-cell-horizontal-indents);
}

.Table thead > tr th.column-sort {
    cursor: pointer;
}

.Table thead > tr th:hover .table-cell__sorter {
    opacity: 0.7;
}

.Table thead:hover > tr th.column-sort.sorted {
    color: var(--primary-default);
    font-weight: bold;
}

.Table thead > tr th.column-sort.sorted {
    color: var(--primary-default);
}

.Table thead > tr th.column-sort.sorted .table-cell__sorter {
    opacity: 1;
}

.Table thead > tr th .table-cell__sorter {
    padding: 0;
    width: var(--table-sort-width);
    opacity: 0;
    border: none;
    background: transparent;
    color: var(--primary-default);
    font-size: 13px;
    cursor: pointer;
    transition: all 0s;
}

.Table thead > tr th .table-cell__sorter .Icon {
    position: relative;
    top: -0.15em;
    width: 14px;
    height: 15px;
    margin-left: 0.57142857em;
    color: var(--primary-default);
}

.Table thead > tr th .table-cell__sorter-asc {
    transform: scaleY(-1);
}

.Table thead > tr th .table-cell__sorter:focus {
    outline: none;
}

.Table thead > tr th .filter.select__multi-mini {
    top: -1px;
    width: var(--table-filter-width);
}

.Table thead > tr th.cell__select-columns {
    padding-right: var(--padding-base);
}

.Table thead > tr th.cell__select-columns > div {
    height: auto;
}

.Table thead > tr th .filter.select-columns {
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 16px;
    margin: 0;
    color: var(--primary-light);
    font-size: 1.6rem;
}

.Table thead > tr th .filter.select-columns .Icon {
    right: 0;
}

.Table thead > tr th:hover .filter .select__button,
.Table thead > tr th .filter .select__button.opened {
    opacity: 1;
}

.Table thead > tr th .filter .select__button {
    display: inline-block;
    padding: 0;
    opacity: 0;
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
    transition: 0s;
    background-color: var(--secondary-lighter);
}

.Table thead > tr th .filter .select__button:hover {
    border-color: var(--primary-light);
}

.Table thead > tr th .filter .select__button.opened {
    border-color: transparent;
}

.Table thead > tr th .filter .select__button.opened:hover {
    border-color: var(--primary-light);
}

.Table thead > tr th .filter .select__button.opened .Icon {
    color: var(--accent-default);
    transform: translate3d(-50%, -50%, 0) rotate(-180deg);
}

.Table thead > tr th.filtered .filter .select__button {
    opacity: 1;
    background-color: var(--accent-default);
    border-color: var(--accent-default);
}

.Table thead > tr th.filtered .filter .select__button .Icon {
    color: var(--white);
}

.Table thead > tr th .select__dropdown {
    width: 250px;
    margin-top: -9px;
    margin-right: 7px;
    font-weight: normal;
}

.Table-filters .rc-tooltip-arrow {
    display: none;
}

.Table-filters .rc-tooltip-inner {
    min-width: 0;
    min-height: 0;
    padding: 0;
    border: none;
    box-shadow: none;
}

.Table-filters .select__dropdown {
    top: 4px;
    right: 12px;
}

.Table-filters.row-filters .select__dropdown {
    right: -24px;
}

/* Table body */
.Table tbody > tr {
    position: relative;
    transition: all 0.3s, height 0s;
}

.Table tbody > tr.row-level-0,
.Table tbody > tr.row-level-1 td {
    border-top: 1px solid var(--secondary-lighter);
}

.Table tbody > tr.row-level-0:first-child {
    border-top-color: transparent;
}

.Table.Table-with-clickable-row tr {
    cursor: pointer;
}

.Table tr.row__selected,
.Table tr.row__selected:hover {
    background-color: var(--secondary-lighter) !important;
}

.Table tbody > tr td {
    padding: 20px 0;
    color: var(--primary-default);
    font-size: 1.4rem;
    line-height: 1.14285714;
    border-bottom: none;
    transition: all 0.3s, border 0s;
}

.Table tbody > tr td.cell-clickable {
    cursor: pointer;
}

.Table tbody > tr td:not(:last-child) {
    padding-right: var(--table-cell-horizontal-indents);
}

.Table tr:not(.row-level-0) td:first-child {
    margin-left: var(--table-cell-expand-size);
    overflow: hidden;
    width: calc(100% - var(--table-cell-expand-size));
}

.Table tr td:first-child:not(:last-child) {
    display: flex;
}

.Table tbody > tr td.text-right.cell-sortable {
    padding-right: calc(var(--table-sort-width) + var(--table-cell-horizontal-padding));
}

.Table tbody > tr td:not(:last-child).text-right.cell-sortable {
    padding-right: calc(var(--table-cell-horizontal-indents) + var(--table-sort-width) + var(--table-cell-horizontal-padding));
}

.Table tbody > tr td.text-right.cell-filterable {
    padding-right: calc(var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tbody > tr td:not(:last-child).text-right.cell-filterable {
    padding-right: calc(var(--table-cell-horizontal-indents) + var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tbody > tr td.text-right.cell-sortable.cell-filterable {
    padding-right: calc(var(--table-sort-width) + var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tbody > tr td:not(:last-child).text-right.cell-sortable.cell-filterable {
    padding-right: calc(var(--table-sort-width) + var(--table-filter-width) + var(--table-cell-horizontal-padding) + var(--table-cell-horizontal-indents));
}

.Table tr td .expand-icon {
    display: inline-block;
    width: var(--table-cell-expand-size);
    padding-left: 10px;
    padding-right: 10px;
}

.Table tr td .expand-icon i {
    color: var(--accent-default);
    cursor: pointer;
    font-size: 1.4rem;
    line-height: 0;
}

.Table.Table-no-header tr td {
    padding: 13px 0;
    line-height: 1.57142857;
}

.Table tr td.no-data {
    color: var(--secondary-light);
    text-align: center;
}

.Table tr td .expand-cell {
    margin-right: calc(var(--table-cell-expand-size) * -1);
    width: 100%;
}

/* Table foot */
.Table tfoot > tr {
    position: relative;
    transition: all 0.3s, height 0s;
    border-top: 1px solid var(--secondary-lighter);
}

.Table tfoot > tr th {
    padding: 20px 0;
    color: var(--primary-default);
    font-size: 1.4rem;
    line-height: 1.14285714;
    border-bottom: none;
    transition: all 0.3s, border 0s;
}

.Table tfoot > tr th:not(:last-child) {
    padding-right: 8px;
}

.Table tfoot > tr th.text-right.cell-sortable {
    padding-right: calc(var(--table-sort-width) + var(--table-cell-horizontal-padding));
}

.Table tfoot > tr th:not(:last-child).text-right.cell-sortable {
    padding-right: calc(var(--table-cell-horizontal-indents) + var(--table-sort-width) + var(--table-cell-horizontal-padding));
}

.Table tfoot > tr th.text-right.cell-filterable {
    padding-right: calc(var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tfoot > tr th:not(:last-child).text-right.cell-filterable {
    padding-right: calc(var(--table-cell-horizontal-indents) + var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tfoot > tr th.text-right.cell-sortable.cell-filterable {
    padding-right: calc(var(--table-sort-width) + var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tfoot > tr th:not(:last-child).text-right.cell-sortable.cell-filterable {
    padding-right: calc(var(--table-sort-width) + var(--table-filter-width) + var(--table-cell-horizontal-padding) + var(--table-cell-horizontal-indents));
}

/* Table mini */
.Table.Table-mini thead {
    display: none;
}

.Table.Table-mini tr td {
    padding: 13px 0;
    line-height: 1.57142857;
}

.Table.Table-mini tr td:first-child {
    display: table-cell;
    padding-left: 30px !important;
}

.Table.Table-mini tr td:last-child {
    padding-right: var(--padding-base) !important;
}

.Table.Table-mini tr td:not(:last-child) {
    padding-right: 10px;
}

/* Table header mini */
.Table-header-mini {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: var(--table-mini-cell-vertical-indents) var(--padding-base);
    color: var(--primary-default);
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.57142857;
    border-bottom: 1px solid var(--secondary-lighter);
}

.Table-header-mini > span {
    padding-right: 5px;
    font-weight: normal;
}

.Table-header-mini .form-group > .select {
    min-width: 70px;
}

.Table-header-mini .select .select__button {
    padding: 0;
    border: 0;
    cursor: pointer;
    background-color: transparent;
}

.Table-header-mini .select .selected__values {
    width: 100%;
    padding-right: 30px;
}

.Table-header-mini .form-group .select .anticon__arrow {
    color: var(--primary-light);
}

.Table-header-mini .form-group .select__option .checkbox-wrapper {
    padding: 12px 8px;
}

.Table-header-mini button {
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;
}

.Table-header-mini .table-cell__sorter-icon {
    margin: 0 4px;
}

.Table-header-mini .table-cell__sorter-asc {
    transform: scaleY(-1);
}

/* Table draggable */
.Table.Table-draggable {
    position: relative;
    overflow: hidden;
}

.Table.Table-draggable th {
    transition: all 130ms ease 0s;
}

.Table.Table-draggable th[draggable=true] {
    cursor: grab;
}

.Table.Table-draggable th[draggable=false] {
    cursor: not-allowed;
}

.Table.Table-draggable th.draggable {
    opacity: 0.5;
}

.Table.Table-draggable > .drag-element {
    position: absolute;
    height: var(--btn-height-sm);
    padding: var(--btn-vertical-padding-sm) var(--btn-horizontal-padding-sm);
    color: var(--primary-light);
    border: 1px solid var(--primary-light);
    box-shadow: none;
    text-align: center;
    min-width: calc(var(--btn-width-base) - 50px);
    margin-top: -50%;
    margin-left: -50%;
    background-color: var(--secondary-lighter);
    border-radius: 2px;
    transform: translate(-50%, -50%);
    font-weight: bold;
}

/* Renders */
.Table tbody .table-cell {
    max-width: 100%;
}

.Table tbody .table-cell_with-subtext {
    @mixin flex normal;
    flex-direction: column;
    min-width: 0;
    max-width: 100%;
}

.Table tbody .table-cell_with-subtext > * {
    @mixin text-overflow;
}

.Table tbody .table-cell_with-current-new div {
    float: right;
}

.Table tbody .table-cell_with-current-new div p {
    margin-bottom: 5px;
}

.Table tbody .table-cell_with-current-new > div:first-child {
    display: inline-block;
}

.Table tbody .table-cell_with-current-new > div:last-child {
    padding-right: 10px;
}

.Table tbody .table-cell_tooltipped {
    max-width: 100%;
    @mixin flex normal, center;
}

.Table.scrollable tbody .table-cell_tooltipped {
    padding-right: 30px;
}

.Table tbody .table-cell_tooltipped-show {
    color: var(--primary-default);
}

.Table tbody .table-cell_tooltipped span {
    margin-right: 4px;
    @mixin text-overflow;
}

.Table tbody .table-cell_linked {
    max-width: 100%;
}

.Table tbody .table-cell_linked span {
    color: var(--table-linked);
    text-decoration: underline;
}

.Table tbody .table-cell_linked:hover span {
    color: var(--table-linked-hover);
}

.Table tbody > tr .table-cell_positive,
.Table tbody > tr .table-cell_highlighted,
.Table tfoot > tr .table-cell_positive,
.Table tfoot > tr .table-cell_highlighted {
    color: var(--positive-default);
    margin-bottom: 0 !important;
}

.Table tbody > tr .table-cell_negative,
.Table tfoot > tr .table-cell_negative {
    color: var(--negative-default);
    margin-bottom: 0 !important;
}

.Table tbody .table-cell_icon {
    color: var(--primary-light);
}

.Table tbody .table-cell_icon i + i {
    margin-left: var(--margin-base);
}

.Table tbody .table-cell_with-button-icon button {
    padding: 0;
    border: 0;
    background-color: transparent;
}

.Table tbody .table-cell_with-button-icon button .table-cell_icon {
    padding-top: 5px;
}

.Table tbody .table-cell_with-button-icon button:hover {
    cursor: pointer;
}

.Table tbody .table-cell_with-button-icon button:focus {
    outline: none;
}

.Table tbody .table-cell_with-checkbox {
    display: table-cell;
    vertical-align: middle;
    padding-top: 7px;
    padding-right: 10px;
}

.Table tbody .table-cell_with-checkbox .checkbox-input {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
}

.Table tbody .table-cell_with-checkbox .checkbox-inner {
    width: 24px;
    height: 24px;
}

.Table tbody .table-cell_with-checkbox .checkbox-inner:after {
    width: 12px;
    height: 12px;
}

.Table tbody .table-cell_with_message p {
    font-size: 16px;
    margin-bottom: 0;
}

.Table tbody .table-cell_with_message.table-cell_with_message-new {
    margin-top: -6px;
}

.Table tbody .table-cell_with_message.table-cell_with_message-new p {
    font-weight: bold;
}

.Table tbody .table-cell_with_message.table-cell_with_message-new:before {
    position: relative;
    top: 37px;
    left: -11px;
    display: block;
    width: 6px;
    height: 6px;
    content: '';
    border-radius: 6px;
    background-color: var(--accent-default);
}

.Table tbody .table-cell_with_message_date.table-cell_with_message_date-replied i {
    display: block;
    padding-bottom: 5px;
    color: var(--primary-light);
    text-align: right;
}

.Table tbody .table-cell_radio > label {
    float: left;
}

.Table tbody .cell-row-selection {
    align-items: center;
}

.Table tbody .row-selection {
    display: flex;
    align-items: center;
    width: calc(var(--checkbox-size) * 2);
    padding-left: 2px;
}

.Table tbody .row-selection .radio {
    top: 0;
}

.Table tbody .cell-row-selection .radio-wrapper,
.Table tbody .cell-row-selection .checkbox-wrapper {
    margin-right: 0;
}
