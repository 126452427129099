.Tags {
    display: flex;
    align-items: flex-start;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.1875;
    justify-content: space-between;
    margin-top: 7px;
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.Tags b {
    margin: var(--tags-indent);
}

.Tags .tag-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--tags-indent);
    padding: var(--tags-indent-inner);
    font-weight: normal;
    border: 1px solid var(--tags-border-color);
    color: var(--primary-light);
    font-size: 1.4rem;
    line-height: 1.14285714;
    border-radius: var(--tags-border-radius);
}

.Tags button {
    display: inline-block;
    margin: var(--tags-indent);
    margin-left: 0;
    padding: var(--tags-indent-button);
    background: transparent;
    color: var(--primary-light);
    border: 0;
    line-height: 1.14285714;
    white-space: nowrap;
}

.Tags button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.Tags .Icon {
    font-size: 1rem;
    margin-left: 9px;
}

@media only screen and (max-width: 992px) {
    .Tags ul {
        flex-direction: column;
    }
}
