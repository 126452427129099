.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
    padding-top: var(--tooltip-distance);
}

.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight > .rc-tooltip-content > .rc-tooltip-arrow {
    border-top-color: var(--background-content);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: var(--background-content);
}

.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: 6px;

}

.rc-tooltip-placement-bottom .rc-tooltip-arrow .rc-tooltip-content,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow .rc-tooltip-content,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow .rc-tooltip-content {
    transform: translateY(calc(23px / 2)) rotate(45deg);
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%);
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
    left: var(--popover-arrow-offset-horizontal);
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    right: var(--popover-arrow-offset-vertical);
}