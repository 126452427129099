.AccordionPanel-header {
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--padding-base);
    color: var(--primary-default);
    font-size: 1.8rem;
    line-height: 1.333;
    cursor: pointer;
    transition: all 0.3s;
}

.AccordionPanel-header:focus {
    outline: none;
}

.AccordionPanel-header i {
    position: static;
    display: inline-block;
    color: var(--accent-default);
    font-size: 2rem;
    font-style: normal;
    text-align: center;
    text-transform: none;
    transform: none;
    margin-right: 20px;
    line-height: 0;
    margin-top: 0;
    transition: var(--transition);
}
