.NotificationItem {
    display: grid;
    grid-template-columns: 3fr 1fr 40px;
    padding: 12px 0;
}

.NotificationItem:not(:last-child) {
    border-bottom: 1px solid var(--secondary-lighter);
}

.NotificationItem .notification__title {
    font-weight: 800;
    font-size: 16px;
    color: var(--primary-default);
}

.NotificationItem .notification__date {
    justify-self: end;
}

.NotificationItem .notification__description {
    grid-column-start: 1;
    grid-column-end: 2 span;
    color: var(--primary-default);
}

.notification__read {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: span 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 2px;
}