.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    left: calc(var(--tooltip-distance) - 23px);
    box-shadow: -1px 1px 0 var(--primary-lighter) !important;

}

.rc-tooltip-placement-right > .rc-tooltip-content > .rc-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}

.rc-tooltip-placement-right > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom > .rc-tooltip-content > .rc-tooltip-arrow {
    left: 6px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: var(--background-content);
    border-left-color: var(--background-content);
}

.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
    padding-left: var(--tooltip-distance);
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%);
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
    top: var(--popover-arrow-offset-vertical);
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    bottom: var(--popover-arrow-offset-vertical);
}