/* stylelint-disable */
.Modal {
    box-sizing: border-box;
    list-style: none;
    color: var(--text-color);
    font-size: var(--font-size-base);
    font-variant: var(--font-variant-base);
    line-height: var(--line-height-base);
    -webkit-font-feature-settings: var(--font-feature-settings-base);
    font-feature-settings: var(--font-feature-settings-base);
    position: relative;
    top: 0;
    width: auto;
    margin: 0 auto;
    padding-bottom: 24px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.Modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--zindex-modal);
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.Modal-title {
    margin: 0;
    font-weight: 500;
    word-wrap: break-word;
    color: var(--modal-title-color);
    font-size: 2.2rem;
    line-height: 1.27272727;
}

.Modal-content {
    position: relative;
    background-color: var(--component-background);
    border: 0;
    -webkit-box-shadow: var(--shadow-2);
    box-shadow: var(--shadow-2);
    border-radius: 0;
    background-clip: inherit;
}

.Modal-header {
    color: var(--text-color);
    background: var(--modal-header-bg);
    border-bottom: var(--border-width-base) var(--border-style-base) var(--border-color-split);
    padding: var(--modal-padding);
    padding-bottom: var(--modal-content-margin);
    border-bottom: 0;
    border-radius: 0;
}

.Modal-body {
    word-wrap: break-word;
    padding: 0 var(--modal-padding);
    color: var(--primary-light);
    font-size: 1.4rem;
    line-height: 1.57142857;
}

.Modal-footer {
    text-align: right;
    background: var(--modal-footer-bg);
    border-top: var(--border-width-base) var(--border-style-base) var(--border-color-split);
    padding: var(--modal-padding);
    padding-top: var(--modal-content-margin);
    border-top: 0;
    border-radius: 0;
}

.Modal-footer button + button {
    margin-bottom: 0;
    margin-left: 8px;
}

.Modal.zoom-center,
.Modal.zoom-appear {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 0;
    -webkit-animation-duration: var(--animation-duration-slow);
    animation-duration: var(--animation-duration-slow);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--zindex-modal-mask);
    height: 100%;
    filter: alpha(opacity=50);
    background-color: var(--modal-mask-bg);

}

.Modal-confirm .Modal-close,
.Modal-mask-hidden {
    display: none;
}

.Modal-open {
    overflow: hidden;
}

.Modal-centered {
    text-align: center;
}

.Modal-centered::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
}

.Modal-centered .Modal {
    top: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.Modal-confirm .Modal-body {
    padding: var(--modal-padding);
}

.Modal-confirm-body-wrapper::before,
.Modal-confirm-body-wrapper::after {
    display: table;
    content: '';
}

.Modal-confirm-body-wrapper::after {
    clear: both;
}

.Modal-confirm-body .Modal-confirm-title {
    display: block;
    overflow: hidden;
    font-weight: 500;
    color: var(--modal-title-color);
    font-size: 2.2rem;
    line-height: 1.27272727;
}

.Modal-confirm-body .Modal-confirm-content {
    margin-top: var(--modal-content-margin);
    color: var(--primary-light);
    font-size: 1.4rem;
    line-height: 1.57142857;
}

.Modal-confirm .Modal-confirm-btns {
    float: none !important;
    margin-top: var(--modal-content-margin);
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.Modal-confirm .Modal-confirm-btns button + button {
    margin-bottom: 0;
    margin-left: 8px;
}

.Modal-footer > div {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
}

.Modal-confirm .Modal-confirm-btns button.ant-btn {
    border-radius: 0;
}

.Modal-footer button,
.Modal-confirm .Modal-confirm-btns button {
    min-width: 0;
    width: 100%;
    padding: 0 var(--indent-base);
}

.Modal-confirm .Modal-confirm-btns button.ant-btn {
    border-radius: 0;
}

.Modal-footer button:first-child,
.Modal-confirm .Modal-confirm-btns button:first-child {
    margin-right: calc(var(--modal-content-margin) / 2);
}

.Modal-footer button:last-child,
.Modal-confirm .Modal-confirm-btns button:last-child {
    margin-left: calc(var(--modal-content-margin) / 2);
}

.Modal-confirm .Modal-confirm-btns button:only-child {
    width: 100%;
    margin: 0;
    max-width: 100%;

}

@media (max-width: 768px) {
    .Modal {
        max-width: calc(100vw - 16px);
        margin: 8px auto;
    }

    .Modal-centered .Modal {
        flex: 1;
    }
}
